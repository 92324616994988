import truncate from 'lodash/truncate';
import translations from '../../lang/fr.json';

function replace(message, replacements = {}) {
    return Object.entries(replacements).reduce((result, [key, value]) => result.replace(`:${key}`, value), message);
}

export function __(key, replacements = {}) {
    if (typeof key === 'string') {
        return replace(CultureDays.locale === 'en' ? key : translations[key] ?? key, replacements);
    } else if (typeof key === 'object' && !Array.isArray(key) && key !== null) {
        return replace(key[CultureDays.locale] || key[CultureDays.locale === 'en' ? 'fr' : 'en'] || '', replacements);
    }
    return '';
}

export function trim(str, length) {
    return truncate(str, { length, separator: /,? +/ });
}

export function cdn(path, params = {}) {
    return path ? `${CultureDays.cdnUrl}/${path}?${new URLSearchParams(params)}` : '';
}
